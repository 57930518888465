import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import ProductPlaceholder from '../assets/produkt-fallback.svg';
import { LazyProgressivePicture, ImageFitTypes } from '../ProgressivePicture';
import { Checkbox } from '../Form/FormItem/FormItems';
import Select from '../Select';
import { Button } from '../Buttons';
import { formatPriceWithDecimalsReduced } from '../../lib/price';
import {
  makeQuantityLabel,
  makeSidesLabel,
  shouldDisplayPrice
} from './catering-helpers';
import Availability from '../Availability';

import cs from 'classnames';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { useDispatch, useSelector } from 'react-redux';

import './CateringCard.scss';
import { addCateredMealToCart } from '../../actions/cart';
import {
  ILoop54CateringItemDetails,
  ILoop54CateringVariant
} from '../../types/loop54Catering';
import { AppState } from '../../reducers';
import {
  loop54AddToCartNotifier,
  loop54ClickNotifier
} from '../../api/endpoints/loopNotify';
import { activeStoreNoSelector } from '../../selectors/assortments';
import ChooseStoreButton from '../BuyButton/ChooseStoreButton';
import LoginPromptButton from '../BuyButton/LoginPromptButton';
import { ga4ImpressionClickTracking_CateredMeal } from '../../lib/analytics/analytics';
import { getProductAvailability } from '../../lib/product';

type TCateringCard = {
  title: string;
  description: string;
  productImageUrl: string;
  variants?: ILoop54CateringVariant[];
  id: string;
  label: string;
  singularLabel: string;
  sides: ILoop54CateringItemDetails[];
  hasVariants: boolean;
  hasSides: boolean;
  isBuffe: boolean;
  defaultVariant: string;
  minOrder: number;
  maxOrder: number;
  setVariant: Function;
  isAuthenticated: boolean;
  redirectToLogin: Function;
  url: string;
  onCarousel?: boolean;
  unit: string;
  impressionListName: string;
  sendCateringItemAddTracking: Function;
  impressionListPosition: number;
  sellable?: boolean;
};
const initSideHasError = {
  showMessage: false,
  highlightError: false
};
const initValidationError = {
  show: false,
  message: ''
};
const CateringCard = (props: TCateringCard) => {
  const {
    title,
    description,
    productImageUrl,
    variants,
    hasSides,
    sides,
    isBuffe,
    defaultVariant,
    minOrder,
    maxOrder,
    sendCateringItemAddTracking,
    setVariant,
    isAuthenticated,
    redirectToLogin,
    url,
    id,
    label,
    singularLabel,
    impressionListName,
    impressionListPosition,
    unit,
    onCarousel,
    sellable
  } = props;
  const storeNumber = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );

  const dispatch = useDispatch();
  const [{ choosenKey, choosenVariant }, setChoosenVariant] = useState({
    choosenKey: defaultVariant,
    choosenVariant: variants?.[defaultVariant][0].id
  });

  const activeAssortment = useSelector((state: AppState) =>
    activeStoreNoSelector(state)
  );

  const matchedVariant =
    variants?.[choosenKey]?.find(x => x.id === choosenVariant) ||
    variants?.[choosenKey]?.[0];

  useEffect(() => {
    setChoosenVariant({
      choosenKey: defaultVariant,
      choosenVariant: variants?.[defaultVariant][0].id
    });
  }, [defaultVariant]);

  const [selectedVariant, setSelectedVariant] = useState(
    makeQuantityLabel(variants?.[defaultVariant], label)[0]
  );
  const price = useMemo(() => {
    return formatPriceWithDecimalsReduced(
      selectedVariant?.storeDetails?.v_current_price
    );
  }, [storeNumber, selectedVariant, variants, defaultVariant]);

  const sortQuantities = (a, b) => (a.quantityFrom > b.quantityFrom ? 1 : -1);
  const sorted = variants?.[defaultVariant].sort(sortQuantities);

  const [quantity, setQuantity] = useState(
    isBuffe
      ? {
          quantityFrom: minOrder
        }
      : {
          quantityFrom: sorted[0].quantityFrom,
          quantityTo: sorted[0].quantityTo
        }
  );
  const [choosenSide, setSide] = useState<ILoop54CateringItemDetails | null>(
    null
  );

  const [sidesHasError, setSideHasError] = useState(initSideHasError);

  const [hasValidationError, setValidationError] = useState(
    initValidationError
  );

  const useMakeOrderLine = () => {
    return {
      plu: selectedVariant.gtin.substr(4, 4),
      gtin: selectedVariant.gtin,
      id: selectedVariant.id,
      parentId: id,
      variant: selectedVariant.name,
      name: title,
      quantity,
      originalPrice: selectedVariant?.storeDetails?.v_ordinary_price,
      totalPrice:
        minOrder || maxOrder
          ? selectedVariant?.storeDetails?.v_current_price *
            quantity.quantityFrom
          : selectedVariant?.storeDetails?.v_current_price,
      price: formatPriceWithDecimalsReduced(
        minOrder || maxOrder
          ? selectedVariant?.storeDetails?.v_current_price *
              quantity.quantityFrom
          : selectedVariant?.storeDetails?.v_current_price
      ),
      side: hasSides ? choosenSide?.name : '',
      productImageUrl,
      sides: sides,
      sideId: hasSides ? choosenSide?.id : '',
      image: productImageUrl,
      unit: unit,
      url: url
    };
  };

  const filterOutSameGtin = (variants: ILoop54CateringVariant[]) => {
    const uniqueVariants: ILoop54CateringVariant[] = [];
    variants?.forEach(v => {
      if (!uniqueVariants.find(unique => unique?.gtin === v?.gtin)) {
        uniqueVariants.push(v);
      }
    });
    return uniqueVariants;
  };

  const priceChecker = useMemo(() => {
    return shouldDisplayPrice(price);
  }, [price]);

  if (priceChecker) {
    return null;
  }

  const variantAvailability = getProductAvailability(
    selectedVariant?.storeDetails
  );

  return (
    <div
      className={cs('l-column-15_xs-60_sm-30_md-20_lg-15_xlg-10', {
        'c-carousel-card': onCarousel
      })}
    >
      <div className={'c-catering-card'} data-productid={id}>
        <Link
          draggable={!onCarousel}
          to={{
            pathname: url,
            state: {
              flyout: true,
              title: ' ',
              nopadding: true,
              tight: false
            }
          }}
          onClick={() => {
            loop54ClickNotifier(id);
            ga4ImpressionClickTracking_CateredMeal({
              defaultVariant,
              impressionListName,
              impressionListPosition,
              minOrder,
              selectedVariant,
              title,
              url
            });
          }}
        >
          <div className={'c-catering-card__image'}>
            <LazyProgressivePicture
              largeImage={{
                url: productImageUrl,
                width: 300,
                height: 300
              }}
              fitType={ImageFitTypes.Contain}
              inheritHeight
              showThumb={false}
              maxWidth={{
                large: 300,
                small: 300
              }}
              placeholder={{
                image: ProductPlaceholder,
                bgColor: 'transparent'
              }}
            />
          </div>
          <div className={'c-catering-card__title'}>
            <h3>{title}</h3>
          </div>
          <div className={'c-catering-card__description mb-5'}>
            <p>{description}</p>
          </div>
        </Link>

        <div className={'c-catering-card__specials mb-20'}>
          {variants &&
            Object.keys(variants).map(variantKey => {
              const variantHasChoosen =
                choosenVariant &&
                variants[variantKey]?.some(
                  variant => variant?.id === choosenVariant
                );
              return Object.keys(variants).length === 1 &&
                variantKey.toLowerCase() === 'standard' ? null : (
                <Checkbox
                  key={variants[variantKey][0].id}
                  isChecked={variantHasChoosen}
                  id={variants[variantKey][0].id}
                  label={variants[variantKey][0].name}
                  className={'mt-10'}
                  onChange={() => {
                    if (variantHasChoosen) {
                      return;
                    }
                    const newSelectedVariant = variants[variantKey]?.find(
                      chosen =>
                        chosen.quantityFrom === matchedVariant?.quantityFrom
                    );
                    setChoosenVariant({
                      choosenKey: variantKey,
                      choosenVariant:
                        newSelectedVariant?.id ?? variants[variantKey][0].id
                    });
                    const quantityLabels = makeQuantityLabel(
                      variants[variantKey]
                    );
                    setSelectedVariant(
                      quantityLabels?.find(
                        variant =>
                          variant.quantityFrom === matchedVariant?.quantityFrom
                      ) ?? quantityLabels[0]
                    );

                    setVariant(variantKey, selectedVariant.id);
                    if (!isBuffe) {
                      setQuantity(
                        newSelectedVariant ??
                          variants[variantKey].sort(sortQuantities)[0]
                      );
                    }
                  }}
                />
              );
            })}
        </div>

        {hasSides && (
          <div className={'c-catering-card__sides'}>
            {sidesHasError.showMessage && (
              <div className={'c-catering-card__error-message'}>
                <p>Vänligen välj ett tillbehör</p>
              </div>
            )}
            <Select
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
              highlight={sidesHasError?.highlightError}
              onChange={sideId => {
                if (sideId.toLowerCase() !== 'välj tillbehör') {
                  setSide(() => {
                    const choosenSide = makeSidesLabel(sides).find(
                      side => side.value === sideId
                    );
                    return choosenSide;
                  });
                  setSideHasError({
                    showMessage: false,
                    highlightError: false
                  });
                } else {
                  setSide(null);
                  setSideHasError({
                    showMessage: true,
                    highlightError: false
                  });
                }
              }}
              placeholder={'Välj tillbehör'}
              options={makeSidesLabel(sides)}
            />
          </div>
        )}
        <div
          className={cs('c-catering-card__lower-section', {
            'c-catering-card__lower-section-with-sides': hasSides
          })}
        >
          {hasValidationError.show && (
            <div className={'c-catering-card__error-message'}>
              <p>{hasValidationError.message}</p>
            </div>
          )}
          <div className={'c-catering-card__price-section mb-20 u-my-20'}>
            <div className={'c-catering-card__price'}>
              {price?.value && (
                <p>
                  {price.decimalCount > 0
                    ? `${price.value},${price.decimal}`
                    : `${price.value}`}{' '}
                  kr {isBuffe ? `/${singularLabel}` : ''}
                </p>
              )}
            </div>
            <div className={'c-catering-card__select-amount'}>
              {isBuffe ? (
                <>
                  <input
                    className={'c-catering-card__amount-input'}
                    type={'number'}
                    value={quantity.quantityFrom}
                    min={minOrder}
                    max={maxOrder}
                    onFocus={() =>
                      setValidationError({
                        show: false,
                        message: `Minsta beställning ${minOrder} ${label}`
                      })
                    }
                    onBlur={({ target }) => {
                      if (
                        !target.value ||
                        Number(target.value) < minOrder ||
                        Number(target.value) > maxOrder
                      ) {
                        setValidationError(
                          Number(target.value) < minOrder
                            ? {
                                show: true,
                                message: `Minsta beställning är ${minOrder} ${label}`
                              }
                            : {
                                show: true,
                                message: `Störta beställning är ${maxOrder} ${label}`
                              }
                        );
                        setQuantity(() => {
                          setValidationError(initValidationError);
                          return { quantityFrom: minOrder };
                        });
                      }
                    }}
                    onChange={e => {
                      const target = Number(e.target.value);

                      if (!target || target < minOrder || target > maxOrder) {
                        setValidationError(
                          !target || target < minOrder
                            ? {
                                show: true,
                                message: `Minsta beställning är ${minOrder} ${label}`
                              }
                            : {
                                show: true,
                                message: `Största beställning är ${maxOrder} ${label}`
                              }
                        );
                      } else {
                        setValidationError({ show: false, message: '' });
                      }

                      setQuantity({
                        quantityFrom: target
                      });
                    }}
                  />{' '}
                  <span className={'c-catering-card__portions'}>{label}</span>
                </>
              ) : (
                <Select
                  defaultValue={selectedVariant.id}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onChange={productId =>
                    setSelectedVariant(() => {
                      const selectedVariant = makeQuantityLabel(
                        filterOutSameGtin(variants?.[choosenKey]),
                        label
                      ).filter(product => product.id === productId);
                      setChoosenVariant({
                        choosenKey,
                        choosenVariant: productId
                      });
                      setQuantity({
                        quantityFrom: selectedVariant[0].quantityFrom,
                        quantityTo: selectedVariant[0].quantityTo
                      });

                      setVariant(choosenKey, selectedVariant[0].id);
                      return selectedVariant[0];
                    })
                  }
                  options={makeQuantityLabel(
                    filterOutSameGtin(variants?.[choosenKey]),
                    label
                  )}
                />
              )}
            </div>
          </div>

          {variantAvailability && (
            <Availability availability={variantAvailability} isCateredMeal />
          )}

          {!isAuthenticated || activeAssortment === null ? (
            !activeAssortment ? (
              <ChooseStoreButton />
            ) : (
              <LoginPromptButton />
            )
          ) : (
            <div className={'c-catering-card__add-to-form-section'}>
              <Button
                fullwidth
                disabled={!sellable}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();

                  if (hasSides && !choosenSide) {
                    setSideHasError({
                      showMessage: true,
                      highlightError: true
                    });
                    return;
                  }
                  // is valid add to cart
                  loop54AddToCartNotifier(id);

                  if (isAuthenticated) {
                    const itemToAdd = useMakeOrderLine();

                    sendCateringItemAddTracking(itemToAdd);
                    dispatch(addCateredMealToCart(itemToAdd));
                  } else {
                    redirectToLogin();
                  }
                }}
              >
                {price?.value
                  ? hasSides && !choosenSide
                    ? 'Välj tillbehör'
                    : 'Lägg till'
                  : 'Inte tillgänglig'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
CateringCard.displayName = 'CateringCard';

export default CateringCard;
