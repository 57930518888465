import React from 'react';
import classNames from 'classnames';
import { WithLink } from '../../Link';
import { EpiserverLink } from '../../../types/episerver/link';
const RoundSpinner = require('../../assets/round-spinner.svg');
const Success = require('../../assets/success.svg');
import './Button.scss';

// TODO: convert to a theme prop for the appearance state, right now you can pass in multiple theme props...
type TButton = {
  active?: boolean;
  buttonType?: 'filter' | 'primary' | 'secondary' | 'transparent' | 'blue';
  className?: string;
  disabled?: boolean;
  fullwidth?: boolean;
  fullwidthBreakPoint?: boolean;
  light?: boolean;
  loading?: boolean;
  minWidth?: number | 'auto';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  tertiary?: boolean;
  prio?: boolean;
  white?: boolean;
  lg?: boolean;
  icon?: any[];
  maxWidth?: string;
  // for the ...rest params
  [x: string]: any;
};

export const Button = ({
  active,
  buttonType,
  children,
  className,
  disabled,
  fullwidth,
  fullwidthBreakPoint,
  light,
  loading,
  minWidth,
  onClick,
  primary,
  secondary,
  success,
  tertiary,
  prio,
  white,
  lg,
  icon,
  maxWidth,
  id,
  ...rest
}: TButton) => {
  const style: React.CSSProperties | undefined = {
    minWidth: minWidth ? minWidth : 'unset',
    maxWidth: maxWidth ? maxWidth : 'unset'
  };

  return (
    <button
      {...rest}
      className={classNames('c-cmdbtn', `${buttonType}`, className, {
        primary,
        secondary,
        white,
        light,
        tertiary,
        prio,
        fullwidth,
        fullwidthBreakPoint,
        active,
        success,
        lg
      })}
      onClick={disabled ? undefined : onClick}
      disabled={disabled || loading || success}
      style={style}
      id={id || 'button'}
    >
      {loading ? (
        <img src={RoundSpinner} alt="Laddar" className="c-cmdbtn__spinner" />
      ) : success ? (
        <img src={Success} alt="Success" className="c-cmdbtn__success" />
      ) : (
        <span className="c-cmdbtn__label">
          {icon && (
            <img src={icon[0]} alt={icon[1]} className="c-cmdbtn__icon" />
          )}

          {children}
        </span>
      )}
    </button>
  );
};

interface LinkButtonProps {
  className?: string;
  primary?: boolean;
  tertiary?: boolean;
  light?: boolean;
  fullwidth?: boolean;
  link: EpiserverLink;
  // for the ...rest params
  [x: string]: any;
}
export const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  className,
  primary = false,
  tertiary = false,
  light = false,
  fullwidth = false,
  link,
  ...rest
}) => (
  <WithLink
    link={link}
    {...rest}
    className={classNames(
      'c-cmdbtn',
      { primary, light, tertiary, fullwidth },
      className
    )}
  >
    {children}
  </WithLink>
);
