import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icons } from '@citygross/icons';
import { NavButton } from '@citygross/components';
import { useDispatch } from 'react-redux';
import { SideModalTabs } from '../SideModal';
import { openSideModal } from '../../slices/sideModalSlice';
import { BodyText, TextTypes } from '@citygross/typography';
import { theme } from '@citygross/design-tokens';
import { NAV_ICON_SIZE } from './constants/constants';

export const UserLoginState = ({ authenticated, closeSearch }) => {
  const dispatch = useDispatch();
  const [href, setHref] = useState('/login');

  useEffect(() => {
    if (authenticated) {
      setHref('/mina-sidor');
    }
  }, [authenticated]);

  return (
    <>
      {authenticated && (
        <a href={href} tabIndex={-1} key="mina-sidor">
          <NavButton
            color="white"
            icon={<Icons.User />}
            size="header"
            href={href}
            borderRadius={100}
            noWrap
            onClick={() => {}}
            noShadow
            hoverBackground={theme.palette.lighter}
            width={NAV_ICON_SIZE}
            height={NAV_ICON_SIZE}
          >
            <BodyText size={TextTypes.TextSize.SMALL} fontWeight="medium">
              Mina Sidor
            </BodyText>
          </NavButton>
        </a>
      )}

      {!authenticated && (
        <NavButton
          tabIndex={0}
          key="login"
          color="white"
          icon={<Icons.User />}
          size="header"
          borderRadius={100}
          noWrap
          onClick={e => {
            e.preventDefault();
            dispatch(openSideModal(SideModalTabs.LOGIN));
            closeSearch();
          }}
          noShadow
          hoverBackground={theme.palette.lighter}
          width={NAV_ICON_SIZE}
          height={NAV_ICON_SIZE}
          id="login-button"
        >
          <BodyText size={TextTypes.TextSize.SMALL} fontWeight="medium">
            Logga in
          </BodyText>
        </NavButton>
      )}
    </>
  );
};

UserLoginState.propTypes = {
  userName: PropTypes.string,
  authenticated: PropTypes.bool.isRequired,
  isLoggedInAsCustomer: PropTypes.bool
};

export default UserLoginState;
