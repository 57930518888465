import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CloseButton, Button } from '../Buttons';
import FlyoutBody from './FlyoutComponents/FlyoutBody';
import FlyoutFooter from './FlyoutComponents/FlyoutFooter';
import './Flyout.scss';
import { focusTrap } from '../../lib/focusTrap';

const FlyoutComp = ({
  tight = true,
  nopadding = false,
  title,
  children,
  onCloseClicked,
  hasFooter = false,
  match: { params }
}) => {
  const flyoutRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const isOffer = params?.hasOwnProperty('offerId');
  const previouslyFocusedElement = useRef<Element | null>(null);

  const closeFlyout = () => {
    if (previouslyFocusedElement.current instanceof HTMLElement)
      previouslyFocusedElement.current.focus();

    onCloseClicked();
  };

  useEffect(() => {
    if (document?.body) {
      previouslyFocusedElement.current = document.activeElement;

      focusTrap({
        exitFunction: closeFlyout,
        ref: flyoutRef
      });
    }
  }, []);

  return (
    <div
      ref={flyoutRef}
      className={classNames('c-panel', {
        tight,
        nopadding,
        ['k-panel']: isOffer
      })}
    >
      <div
        className={classNames('c-stdpnlcnt', {
          'has-foot': hasFooter,
          'offer-header': isOffer,
          nohead: !title
        })}
      >
        {title ? (
          <div
            className={classNames('pnlhead', {
              'pnlhead--is-scrolled': isScrolled
            })}
          >
            <div className="closepanel">
              <CloseButton onClick={closeFlyout} />
            </div>

            <h2>{title}</h2>
          </div>
        ) : (
          <div className="closepanel">
            <CloseButton onClick={closeFlyout} />
          </div>
        )}

        <FlyoutBody isScrolled={setIsScrolled}>{children}</FlyoutBody>

        {hasFooter && (
          <FlyoutFooter>
            <Button light onClick={onCloseClicked}>
              Avbryt
            </Button>
          </FlyoutFooter>
        )}
      </div>
    </div>
  );
};

FlyoutComp.propTypes = {
  tight: PropTypes.bool,
  nopadding: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  onCloseClicked: PropTypes.func.isRequired,
  hasFooter: PropTypes.bool,
  confirmModalRef: PropTypes.any.isRequired,
  show: PropTypes.bool.isRequired
};

const Flyout = props => props.show && <FlyoutComp {...props} />;

export default Flyout;
