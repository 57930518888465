import React from 'react';

type TFocusTrap = {
  exitFunction: () => void;
  ref: React.MutableRefObject<HTMLDivElement | null>;
};

export const focusTrap = ({ exitFunction, ref }: TFocusTrap) => {
  const elements =
    'button:not([disabled]), [href]:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])';

  const focusableElements = ref.current?.querySelectorAll(elements);

  if (
    focusableElements &&
    focusableElements.length > 0 &&
    focusableElements[0] instanceof HTMLElement
  ) {
    focusableElements[0].focus();
  }

  const handleTabKey = (e: KeyboardEvent) => {
    if (!ref.current) return;

    const focusable = Array.from(
      ref.current.querySelectorAll<HTMLElement>(elements)
    );

    if (focusable.length === 0) return;

    const firstElement = focusable[0];
    const lastElement = focusable[focusable.length - 1];

    if (e.key === 'Tab') {
      if (e.shiftKey && document.activeElement === firstElement) {
        e.preventDefault();
        lastElement.focus();
      } else if (!e.shiftKey && document.activeElement === lastElement) {
        e.preventDefault();
        firstElement.focus();
      }
    } else if (e.key === 'Escape') exitFunction();
  };

  document.addEventListener('keydown', handleTabKey);

  return () => {
    document.removeEventListener('keydown', handleTabKey);
  };
};
