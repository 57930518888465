import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { withMemo } from '../../lib/withMemo';
import classNameFromLocation from '../../lib/classNameFromLocation';

import Footer from '../../components/Footer';
import HelmetWrap from '../../components/HelmetWrap';

import { ROUTE_OFFER_PATTERN } from '../../lib/route/defaults';
import pathToRegexp from 'path-to-regexp';
import './DefaultLayout.scss';

const DefaultLayout = ({
  children,
  className,
  container = true,
  footerNav,
  customerService,
  facebookUrl,
  instagramUrl,
  twitterUrl,
  youtubeUrl,
  seo,
  notificationStatus,
  location,
  thinFooter,
  noFooter,
  narrowLayout,
  // TODO: how about we refactor all those variants into different layouts..
  wideWithCart,
  narrowFooter,
  ...rest
}) => {
  const {
    description,
    descriptionFallback,
    keywords,
    link,
    shareImage,
    title
  } = seo;

  const pageClassName = location.pathname
    ? classNameFromLocation(location.pathname)
    : '';

  return (
    <React.Fragment>
      <main
        className={classnames(
          narrowLayout ? 'b-container fluid' : 'b-main',
          { noticeHidden: !notificationStatus },
          className,
          pageClassName
        )}
        style={{ paddingTop: 0 }}
        {...rest}
        id="b-main"
      >
        <HelmetWrap
          description={description}
          descriptionFallback={descriptionFallback}
          keywords={keywords}
          link={link}
          shareImage={shareImage}
          title={title}
          isOfferPage={pathToRegexp(ROUTE_OFFER_PATTERN).test(
            location.pathname
          )}
        />
        <div
          className={classnames(
            narrowLayout
              ? 'b-main'
              : {
                  'b-container fluid': container || className === 'no-cart'
                },
            wideWithCart ? 'wideWithCart' : ''
          )}
        >
          <div className="b-main-inner">{children}</div>

          {noFooter !== true && narrowLayout && !wideWithCart ? (
            <Footer
              footerNav={footerNav}
              customerService={customerService}
              facebookUrl={facebookUrl}
              instagramUrl={instagramUrl}
              twitterUrl={twitterUrl}
              youtubeUrl={youtubeUrl}
              thinFooter={thinFooter}
            />
          ) : null}
        </div>

        {noFooter !== true && (!narrowLayout || wideWithCart) ? (
          <Footer
            footerNav={footerNav}
            customerService={customerService}
            facebookUrl={facebookUrl}
            instagramUrl={instagramUrl}
            twitterUrl={twitterUrl}
            youtubeUrl={youtubeUrl}
            thinFooter={thinFooter}
            narrowFooter={narrowFooter}
          />
        ) : null}
      </main>
    </React.Fragment>
  );
};

DefaultLayout.propTypes = {
  seo: PropTypes.shape({
    description: PropTypes.string,
    descriptionFallback: PropTypes.string,
    keywords: PropTypes.string,
    notificationStatus: PropTypes.bool,
    link: PropTypes.string,
    shareImage: PropTypes.shape({
      height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      url: PropTypes.string,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    title: PropTypes.string
  })
};

export default withMemo(DefaultLayout, ['seo', 'notificationStatus', 'match']);
