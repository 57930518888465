import React from 'react';
import * as styles from './SideModal.styles';
// eslint-disable-next-line import/namespace, import/named
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/display-name */
import { Icons } from '@citygross/icons';
import { IconButton } from '@citygross/components';
import { fireCallbackOnEnter } from '../../lib/utils';

type TSideModalHeader = {
  title?: string;
  closeSideModal?: () => void;
  leftElement?: React.ReactNode;
  hideCloseButton?: boolean;
  marginBottom?: number;
  showBackArrow?: boolean;
  backArrowOnClick?: () => void;
  goToStepOnBack?: number;
  setStep?: () => void;
  activeStep?: number;
  isLogin?: boolean;
  isCart?: boolean;
};

const SideModalHeader = ({
  title,
  closeSideModal,
  leftElement,
  hideCloseButton,
  marginBottom,
  showBackArrow,
  backArrowOnClick,
  setStep,
  isLogin,
  isCart
}: TSideModalHeader) => {
  const onBackHandler = () => {
    setStep && setStep();
    backArrowOnClick && backArrowOnClick();
  };

  return (
    <styles.SideModalHeaderWrapper
      leftElementExists={Boolean(leftElement)}
      titleExists={Boolean(title) || hideCloseButton}
      marginBottom={marginBottom}
      isLogin={isLogin}
      isCart={isCart}
    >
      {leftElement ? (
        leftElement
      ) : showBackArrow ? (
        <div
          style={{ cursor: 'pointer' }}
          onClick={onBackHandler}
          tabIndex={0}
          onKeyDown={fireCallbackOnEnter(() => {
            onBackHandler();
          })}
        >
          <IconButton
            borderRadius={100}
            color="white"
            height={40}
            width={40}
            icon={<Icons.ArrowLeft width={20} height={20} />}
            noShadow
            hoverBackground="#E8E8E8"
          />
        </div>
      ) : null}

      {title && (
        <styles.SideModalHeaderTitle>{title}</styles.SideModalHeaderTitle>
      )}

      {!hideCloseButton && (
        <styles.CloseButtonWrapper>
          <IconButton
            borderRadius={100}
            color="lightest"
            height={40}
            width={40}
            onClick={closeSideModal}
            icon={<Icons.Cross width={20} height={20} />}
            noShadow
            hoverBackground="#E8E8E8"
          />
        </styles.CloseButtonWrapper>
      )}
    </styles.SideModalHeaderWrapper>
  );
};

export default SideModalHeader;
