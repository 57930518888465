import React, { useState, useEffect, useRef } from 'react';

import ProductList from '../ProductList';
import { setProductFavorite, removeFavorite } from '../../actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRelatedProducts } from '../../api/endpoints/product';
import { AppState } from '../../reducers';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { IExtendedProduct } from '../../types/storefront/product';
import { loopProductDefaults } from '../../selectors/product/productSelectors';
import { ga4ImpressionList } from '../../lib/analytics/analytics';

interface IProps {
  categoryCode: string;
  bfabId: string;
  offerName?: string;
}

const RelatedProducts: React.FC<IProps> = ({
  offerName,
  categoryCode,
  bfabId
}) => {
  const [products, setProducts] = useState<IExtendedProduct[]>([]);
  const dispatch = useDispatch();
  const activeStore = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );
  const componentMounted = useRef(true);

  useEffect(() => {
    if (!categoryCode || !activeStore) {
      return;
    }
    fetchRelatedProducts(bfabId, 4, offerName).then(({ data }) => {
      if (data.items && data.items?.length > 0) {
        if (componentMounted.current) {
          const products = data.items?.map(item => loopProductDefaults(item));
          setProducts(products);
        }
        try {
          const impressionListName = 'ProductSingle - Rekommenderade varor';

          ga4ImpressionList(data.items, impressionListName);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    });
  }, [activeStore]);

  if (!products || !products.length) {
    return null;
  }

  return (
    <div className="c-productdetails__related">
      <h2>Liknande varor</h2>
      <ProductList
        fetching={false}
        setProductFavorite={(id, item) =>
          dispatch(setProductFavorite(id, item))
        }
        removeFavorite={x => dispatch(removeFavorite(x))}
        items={products}
        colStructure={'l-column-15_xs-30_sm-20_md-15_lg-15_xlg-15-mobileGutter'}
      />
    </div>
  );
};

export default RelatedProducts;
