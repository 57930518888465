import React, { useState } from 'react';
import { FavoriteButton } from '../../../components/Buttons';
import Image from '../../../components/Image';
import BuyButton from '../../../components/BuyButton';
import Availability from '../../../components/Availability';
import './ProductSingleDetails.scss';
import {
  getProductAvailability,
  getProductDescription,
  loopDetermineAvailable,
  setImage
} from '../../../lib/product';
import { IExtendedProduct } from '../../../types/storefront/product';
import { getPriceInfo } from '../../../lib/price';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers';
import {
  productFavoritesSelector,
  favoriteProductsLoadingSelector
} from '../../../selectors/product/productSelectors';
import { removeFavorite, setProductFavorite } from '../../../actions/auth';
import WarningLabel from '../../../components/WarningLabel';
import ProductMaxAmount from './ProductMaxAmount';
import {
  EPriceSize,
  EPriceVariant,
  EProductHeader,
  PriceStripe,
  PriceTag,
  ProductHeader
} from '@citygross/components';
import PriceDetails from '../../../components/PriceDetails';
import Markings from '../../../components/Markings';
import { TextTypes } from '@citygross/typography';
import { PromotionConditionsBox } from './PromotionConditionsBox';

type TProductSingleDetails = {
  product: IExtendedProduct;
};

const ProductSingleDetails: React.FC<TProductSingleDetails> = ({ product }) => {
  const dispatch = useDispatch();

  const favorite = useSelector((state: AppState) =>
    productFavoritesSelector(state).find(x => x.itemNo === product.id)
  );

  const isFavoriteLoading = useSelector((state: AppState) =>
    Boolean(favoriteProductsLoadingSelector(state).find(x => x === product.id))
  );

  const favoriteButtonClick = () => {
    favorite
      ? dispatch(removeFavorite(favorite))
      : dispatch(
          setProductFavorite(product.id, {
            name: product.name,
            value: Number(priceToDisplay)
          })
        );
  };

  const metaAvailabilityContent =
    loopDetermineAvailable(product.productStoreDetails) ||
    !product.productStoreDetails?.p_has_price
      ? 'https://schema.org/OutOfStock'
      : 'https://schema.org/InStock';

  const productAvailability = getProductAvailability(
    product.productStoreDetails
  );

  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);

  const [showMax, setShowMax] = useState<boolean>(false);

  const priceValidUntil = date.toLocaleDateString('sv-SE');

  const image = setImage(product.images, 0);
  const imageWidth = 300;
  const imageSrc = image.url + '?w=' + imageWidth;

  const {
    hasReturnablePackageDeposit,
    limitedStock,
    multiPrice,
    priceVariant,
    priceObjectToDisplay,
    priceToDisplay,
    priceToDisplayUnit,
    productStorePrices,
    promotion
  } = getPriceInfo(product);

  const productHeaderSubTitle = getProductDescription({
    bfCategoryCode: product.bfCategoryCode,
    brand: product.brand,
    countryOfOrigin: product.countryOfOrigin,
    descriptiveSize: product.descriptiveSize
  });

  return (
    <div
      className="details-block"
      data-product-id={product.gtin}
      itemType={'http://schema.org/Product'}
      itemScope
    >
      <meta itemProp="productID" content={product.gtin} />
      <meta itemProp="sku" content={product.gtin} />
      <meta itemProp="brand" content={product.brand || ''} />
      <meta itemProp="url" content={'https://www.citygross.se' + product.url} />
      {image && (
        <link itemProp="image" href={'https://www.citygross.se' + image.url} />
      )}
      <meta itemProp="name" content={product.name} />
      <meta
        itemProp="description"
        content={product.description || product.brand || product.name}
      />

      <div className="left">
        {(productAvailability || limitedStock) && (
          <div className="info-container">
            {productAvailability && (
              <Availability availability={productAvailability} isDetailPage />
            )}

            {limitedStock && (
              <WarningLabel label="Begr. tillgänglighet" isDetailPage />
            )}
          </div>
        )}

        <ProductHeader
          location={EProductHeader.DETAIL_PAGE}
          title={product.name}
          subTitle={productHeaderSubTitle}
        />

        <PriceDetails
          currentPrice={priceObjectToDisplay}
          displayLowestPriceLast30Days={
            productStorePrices?.promotions?.[0]?.displayLowestPriceLast30Days
          }
          fontSize={TextTypes.TextSize.EXTRASMALL}
          hasReturnablePackageDeposit={hasReturnablePackageDeposit}
          lowestPriceLast30Days={productStorePrices?.lowestPriceLast30Days}
          ordinaryPrice={productStorePrices?.ordinaryPrice}
          priceVariant={priceVariant}
          unit={product.unit}
        />

        <div>
          {promotion && priceToDisplayUnit && (
            <PromotionConditionsBox
              promotion={promotion}
              unit={priceToDisplayUnit}
            />
          )}

          <ProductMaxAmount showMax={showMax} />

          <div className="buttons-container">
            <BuyButton
              noMarginQty
              product={product}
              primaryButton
              setShowMax={setShowMax}
            />

            <FavoriteButton
              active={Boolean(favorite)}
              handleClick={favoriteButtonClick}
              loading={Boolean(isFavoriteLoading)}
              id={'pdp-' + product.id}
            />
          </div>
        </div>
      </div>

      <div className="right">
        <div className="image-container">
          <Image src={imageSrc} alt={image.alt} title={image.alt} />

          {priceVariant === EPriceVariant.PRIO && (multiPrice ?? 0) > 1 && (
            <div className="price-stripe-container">
              <PriceStripe
                size={EPriceSize.LARGE}
                variant={EPriceVariant.PRIO}
              />
            </div>
          )}

          {!!product.mappedMarkings?.length && (
            <div className="markings">
              <Markings markings={product.mappedMarkings} />
            </div>
          )}
        </div>

        {priceToDisplay && (
          <div
            className="price-tag-container"
            itemProp="offers"
            itemType="http://schema.org/Offer"
            itemScope
          >
            <meta itemProp="availability" content={metaAvailabilityContent} />
            <meta itemProp="priceValidUntil" content={priceValidUntil} />
            <meta
              itemProp="price"
              content={String(productStorePrices?.ordinaryPrice?.price || 0)}
            />
            <meta itemProp="priceCurrency" content="SEK" />

            <PriceTag
              multiPrice={multiPrice}
              pant={hasReturnablePackageDeposit}
              price={priceToDisplay}
              unit={priceToDisplayUnit}
              variant={priceVariant}
              size={EPriceSize.LARGE}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductSingleDetails;
