import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Helmet from 'react-helmet';
import MasterContainer from '../MasterContainer';
import Routing from '../../containers/Routing/Routing';
import Header from '../Header';
import ConfirmModal from '../../containers/ConfirmModal';
import Toast from '../Toast';
import Overlay from '../../containers/Overlay';
import CriticalRequestsInfo from '../CriticalRequestsInfo';
import { cartPropType } from '../../prop-types/index';
import HeaderHeightProvider, {
  HeaderHeightConsumer
} from '../HeaderHeightContext';
import {
  getApplicationSettings,
  toggleServiceLayer,
  toggleOverlay,
  togglePortalOverlay
} from '../../actions/app';
import { openInFlyout, closeAll } from '../../actions/flyout';
import { loginUser, logoutUser, allUserInfo } from '../../actions/auth';
import './App.scss';
import { authenticatedSelector, fullNameSelector } from '../../selectors/user';
import {
  rootRoutesSpliterSelector,
  activeNavigationSelector
} from '../../selectors/routing/routingSelector';
import useClearCookiesOnConsentChange from '../../hooks/useClearCookiesOnConsentChange';
import ClearCookies from '../ClearCookies';
import { NewCartPreviewModal } from '../Sortiment/NewCartPreview/NewCartPreviewModal';
import { resetNewCart } from '../../actions/newCartPrep';
import { handleStoreIdChange } from '../../actions/cart';
import { useEffect } from 'react';
import { getElementByIdAsync } from '../../lib/utils';
import { SideModal } from '../../components/SideModal';
import Cookie from 'js-cookie';
import NewModal from '../NewModal';
import { LazyProgressivePicture } from '../ProgressivePicture';

let node, sNode, flyoutRef, overlayRef, confirmModalRef;
const plainUrls = [];

const plainUrlsRegexp = `(${plainUrls.map(obj => obj.path).join('|')})`;

export const App = ({
  navigation,
  settings,
  visibilitySettings,
  toggleServiceLayer,
  openInFlyout,
  location,
  authenticated,
  loginUser,
  logoutUser,
  fetchUserInfo,
  closeAll,
  userName,
  forgotPasswordClicked,
  menuTabActive
}) => {
  const promotionModalSeen = Cookie.get('promotionModalSeen');
  const [showPromotionModal, setShowPromotionModal] = useState(false);
  const closePromotionModal = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(3, 0, 0, 0);
    setShowPromotionModal(false);
    Cookie.set('promotionModalSeen', 'true', { expires: tomorrow });
  };
  useEffect(() => {
    setShowPromotionModal(
      settings?.promotion?.visible && promotionModalSeen !== 'true'
    );
  }, [promotionModalSeen, settings]);

  const dispatch = useDispatch();
  const currentCart = useSelector(state => state.cart);
  const assortments = useSelector(state => state.assortments);
  const newCart = useSelector(state => state.newCartPrep);

  const {
    cookieCategoriesToClear,
    clearCookies
  } = useClearCookiesOnConsentChange();

  useEffect(() => {
    getElementByIdAsync('CookiebotWidget').then(widget => {
      if (location.pathname === '/kundservice/om-cookies')
        widget.style.display = 'block';

      if (
        location.pathname !== '/kundservice/om-cookies' &&
        widget.style.display !== 'none'
      )
        widget.style.display = 'none';
    });
  }, [location]);

  return (
    <HeaderHeightProvider>
      <HeaderHeightConsumer>
        {context => (
          <MasterContainer {...visibilitySettings}>
            <Helmet titleTemplate={'%s - City Gross'} />
            <div
              id="viewport"
              className={
                !menuTabActive && context.stickyHeader ? 'sticky-header' : ''
              }
            >
              <Switch>
                <Route
                  exact
                  path={plainUrlsRegexp}
                  render={() => (
                    <Header
                      {...plainUrls.find(obj => obj.path === location.pathname)
                        .headerProps}
                      headerHeightContext={context}
                    />
                  )}
                />
                <Route
                  path="*"
                  render={() => (
                    <div>
                      <Header
                        key="Header"
                        siteMessage={settings.message}
                        location={location}
                        userName={userName}
                        navTop={navigation.navTop}
                        navSection={navigation.navSection}
                        toggleServiceLayer={toggleServiceLayer}
                        onLoginSuccess={closeAll}
                        loginClicked={openInFlyout}
                        authenticated={authenticated}
                        loginUser={loginUser}
                        logoutUser={logoutUser}
                        fetchUserInfo={fetchUserInfo}
                        forgotPasswordClicked={forgotPasswordClicked}
                        {...visibilitySettings}
                        headerHeightContext={context}
                        menuTabActive={menuTabActive}
                      />
                    </div>
                  )}
                />
              </Switch>

              <div className="b-searchcontainer" ref={n => (node = n)} />

              <Routing
                flyoutRef={flyoutRef}
                confirmModalRef={confirmModalRef}
                overlayRef={overlayRef}
              />

              <SideModal confirmModalRef={confirmModalRef} />

              <Overlay headerHeightContext={context} />
              {/* @TODO remove after catering */}

              <NewModal
                isOpen={showPromotionModal}
                closeModal={closePromotionModal}
                buttonText={settings?.promotion?.promotionButtonLabel}
              >
                {settings?.promotion?.desktopImage && (
                  <LazyProgressivePicture
                    largeImage={settings?.promotion?.desktopImage}
                    smallImage={settings?.promotion?.mobileImage}
                  />
                )}
              </NewModal>

              <ConfirmModal />

              {newCart.show && (
                <NewCartPreviewModal
                  cartId={currentCart?.meta?.id}
                  cartToBeChangedId={newCart?.cartToBeChanged}
                  handleSubmit={async () => {
                    dispatch(resetNewCart());
                    if (currentCart?.meta?.id) {
                      dispatch(
                        handleStoreIdChange(
                          currentCart.meta.id,
                          assortments.storeNo
                        )
                      );
                    }
                  }}
                />
              )}

              <Toast />
              <CriticalRequestsInfo />

              <div id="#overlayPortal" ref={ref => (overlayRef = ref)} />
              <div id="#flyoutPortal" ref={ref => (flyoutRef = ref)} />
              <div
                id="#confirmModalPortal"
                ref={ref => (confirmModalRef = ref)}
              />
            </div>
            {clearCookies && (
              <ClearCookies cookieCategoriesToClear={cookieCategoriesToClear} />
            )}
          </MasterContainer>
        )}
      </HeaderHeightConsumer>
    </HeaderHeightProvider>
  );
};

App.propTypes = {
  showOrderModal: PropTypes.bool,
  navigation: PropTypes.shape({
    allRoutes: PropTypes.array,
    error: PropTypes.string,
    fetching: PropTypes.bool,
    footer: PropTypes.array,
    hasFetched: PropTypes.bool,
    header: PropTypes.array,
    navSection: PropTypes.array,
    navTop: PropTypes.array,
    pathLookup: PropTypes.objectOf(PropTypes.object),
    rootRoutes: PropTypes.array,
    routes: PropTypes.array,
    tree: PropTypes.shape({
      children: PropTypes.array,
      id: PropTypes.number,
      link: PropTypes.shape({
        internal: PropTypes.bool,
        url: PropTypes.string
      }),
      name: PropTypes.string,
      props: PropTypes.any,
      type: PropTypes.string,
      visible: PropTypes.bool
    })
  }),
  settings: PropTypes.shape({
    RecaptchaPublicKey: PropTypes.string,
    cart: cartPropType,
    customerServiceUrl: PropTypes.string,
    deliveryTexts: PropTypes.shape({
      deliverySlotsText: PropTypes.string,
      deliveryTruckText: PropTypes.string
    }),
    facebookUrl: PropTypes.string,
    faqCats: PropTypes.array,
    footerNavigation: PropTypes.shape({
      footerNavgationBlocks: PropTypes.array,
      id: PropTypes.number,
      instagramUrl: PropTypes.string,
      isPublished: PropTypes.bool
    }),
    id: PropTypes.number,
    instagramUrl: PropTypes.string,
    ispublished: PropTypes.bool,
    login: PropTypes.shape({
      text: PropTypes.string
    }),
    message: PropTypes.shape({
      message: PropTypes.string,
      status: PropTypes.string
    }),
    mobileNavigation: PropTypes.array,
    modal: PropTypes.shape({
      isToggled: PropTypes.bool
    }),
    name: PropTypes.string,
    notFoundArea: PropTypes.array,
    phoneEmail: PropTypes.shape({
      email: PropTypes.string,
      phone: PropTypes.string
    }),
    productMarkings: PropTypes.array,
    recipeMarkings: PropTypes.array,
    register: PropTypes.shape({
      text: PropTypes.string,
      title: PropTypes.string
    }),
    seo: PropTypes.shape({
      description: PropTypes.string,
      keywords: PropTypes.array,
      shareImage: PropTypes.shape({
        height: PropTypes.number,
        id: PropTypes.number,
        ispublished: PropTypes.bool,
        name: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
        width: PropTypes.number
      }),
      title: PropTypes.string
    }),
    specialDiet: PropTypes.string,
    storeServices: PropTypes.array,
    topNavigation: PropTypes.array,
    twitterUrl: PropTypes.string,
    type: PropTypes.string,
    youtubeUrl: PropTypes.string
  }),
  visibilitySettings: PropTypes.shape({
    isSearchToggled: PropTypes.bool,
    isToggled: PropTypes.bool,
    serviceLayerToggled: PropTypes.bool,
    visible: PropTypes.bool
  }),
  toggleServiceLayer: PropTypes.func,
  openInFlyout: PropTypes.func,
  toggleOverlay: PropTypes.func,
  togglePortalOverlay: PropTypes.func,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.any
  }),
  authenticated: PropTypes.bool,
  loginUser: PropTypes.func,
  logoutUser: PropTypes.func,
  fetchUserInfo: PropTypes.func,
  closeAll: PropTypes.func,
  userName: PropTypes.string,
  userFirstName: PropTypes.string,
  forgotPasswordClicked: PropTypes.bool,
  push: PropTypes.func
};

App.defaultProps = {
  navigation: {},
  settings: {},
  visibilitySettings: {}
};

const mapStateToProps = (state, props) => {
  return {
    menuTabActive:
      state.assortments.menuTabActive | state.assortments.lastSelectedActive,
    authenticated: authenticatedSelector(state),
    activeSiteId: state.assortments.siteId,
    modal: state.app.modal,
    assortmentsModalActive: state.assortments.storePickerModalActive,
    assortmentsInfoModalActive: state.assortments.assortmentInfoModalActive,
    userName: fullNameSelector(state),
    visibilitySettings: {
      isToggled: state.app.mobileNav.isToggled,
      isSearchToggled: state.app.search.isToggled,
      visible: state.shoppingOrder.visible,
      serviceLayerToggled: state.app.serviceLayer.isToggled
    },
    navigation: {
      ...state.navigation,
      ...rootRoutesSpliterSelector(state),
      tree: activeNavigationSelector(state, props)
    },
    settings: {
      ...state.app.settings
    },
    newCartPrep: state.newCartPrep
  };
};

const mapDispatchToProps = {
  getApplicationSettings,
  toggleServiceLayer,
  toggleOverlay,
  togglePortalOverlay,
  openInFlyout,
  closeAll,
  loginUser,
  logoutUser,
  allUserInfo,
  push
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
