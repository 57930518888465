export const DEFAULT_HEADER_HEIGHT = 116;
export const DELIVERY_TAB_HEIGHT = 50;
export const TOPNAV_HEIGHT = 66;
export const HEADER_BREAKPOINT = 991;
export const HEADER_MAX_WIDTH = 1860;
export const HEADER_MENU_BREAKPOINT = 1160;
export const NAV_ICON_SIZE = 42;
export const PRODUCT_PAGES = [
  'matvaror',
  'recept',
  'matkassar',
  'catering',
  '/sok'
];
