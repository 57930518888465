import React from 'react';
import { Button } from '../Buttons';
import { useDispatch } from 'react-redux';
import { openSideModal } from '../../slices/sideModalSlice';
import { SideModalTabs } from '../SideModal';

type TButtonProps = {
  minWidth?: number | 'auto';
  maxWidth?: string | 'auto';
  fullWidth?: boolean;
  id?: string;
};

const LoginPromptButton = ({
  minWidth,
  maxWidth,
  fullWidth = true,
  id
}: TButtonProps) => {
  const dispatch = useDispatch();
  return (
    <Button
      fullwidth={fullWidth}
      minWidth={minWidth}
      maxWidth={maxWidth}
      onClick={event => {
        event.preventDefault();
        dispatch(openSideModal(SideModalTabs.LOGIN));
      }}
      id={id || 'login-button'}
    >
      Logga in
    </Button>
  );
};

export default LoginPromptButton;
