import { NavButton } from '@citygross/components';
import { Icons } from '@citygross/icons';
import React from 'react';

type TCartButton = {
  buttonBadge?: string;
  handleClick?: () => void;
  children?: React.ReactNode;
};

export const CartButtonConstants = {
  minWidth: 115,
  badgeMinWidth: 24
};

const CartButton = ({ buttonBadge, handleClick, children }: TCartButton) => {
  return (
    <NavButton
      tabIndex={0}
      color={'primary'}
      icon={<Icons.ShoppingCart width={24} height={24} />}
      minWidth={CartButtonConstants.minWidth}
      badgeMinWidth={CartButtonConstants.badgeMinWidth}
      size={'header'}
      buttonBadge={buttonBadge}
      borderRadius={100}
      noWrap
      onClick={handleClick}
      noShadow
      hoverBackground="#F5D800"
      childGap={8}
      width={42}
      keepLabel
      height={42}
      id="cart-button"
    >
      {children}
    </NavButton>
  );
};

export default CartButton;
