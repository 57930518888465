import React from 'react';
import { dateAsFormat } from '../../lib/delivery';
import { BodyText, TextTypes } from '@citygross/typography';

type TAvailabilityText = {
  availableFrom?: string;
  availableTo?: string;
  expired?: boolean;
};

type TAvailabilityTextProps = {
  availability: TAvailabilityText;
  fontSize?: TextTypes.TextSize;
  isCateredMeal?: boolean;
};

const AVAILABILITY_TEXT = {
  PRODUCT: {
    availableFrom: 'Åter i lager',
    availableTo: 'Tillgänglig till'
  },
  CATERED_MEALS: {
    availableFrom: 'Tillgänglig',
    availableTo: 'Tillgänglig till'
  },
  EXIPRED: 'Utgått'
};

export const AvailabilityText = ({
  availability,
  fontSize,
  isCateredMeal
}: TAvailabilityTextProps) => {
  const { availableFrom, availableTo, expired } = availability;

  const availableFromText = isCateredMeal
    ? AVAILABILITY_TEXT.CATERED_MEALS.availableFrom
    : AVAILABILITY_TEXT.PRODUCT.availableFrom;
  const availableToText = isCateredMeal
    ? AVAILABILITY_TEXT.CATERED_MEALS.availableTo
    : AVAILABILITY_TEXT.PRODUCT.availableTo;

  return (
    <BodyText size={fontSize}>
      {availableFrom ? (
        <>
          {availableFromText} {dateAsFormat(new Date(availableFrom), 'd/M')}
        </>
      ) : availableTo ? (
        <>
          {availableToText} {dateAsFormat(new Date(availableTo), 'd/M')}
        </>
      ) : expired ? (
        <>{AVAILABILITY_TEXT.EXIPRED}</>
      ) : null}
    </BodyText>
  );
};
