import React from 'react';
import className from 'classnames';
import './FavoriteButton.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers';
import { authenticatedSelector } from '../../../selectors/user';
import { redirectToLogin } from '../../../actions/app';
import { fireCallbackOnEnter } from '../../../lib/utils';

const RoundSpinner = require('../../assets/round-spinner.svg');

type TFavoriteButton = {
  light?: boolean;
  slideToggle?: boolean;
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  handleClick: Function;
  id?: string;
};

const FavoriteButton = ({
  light = true,
  slideToggle,
  active,
  disabled = false,
  loading = false,
  handleClick = () => {},
  id
}: TFavoriteButton) => {
  const dispatch = useDispatch();

  const authenticated = useSelector((state: AppState) =>
    authenticatedSelector(state)
  );
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.preventDefault();

    if (!loading) {
      authenticated ? handleClick() : dispatch(redirectToLogin());
    }
  };

  return (
    <div
      className={className('c-favoritebtn', {
        active,
        light,
        slideToggle,
        disabled,
        loading
      })}
      tabIndex={0}
      role={'button'}
      onKeyDown={fireCallbackOnEnter(onClick)}
      onClick={onClick}
      id={`favorite-button-${id}`}
    >
      {loading && (
        <img
          src={RoundSpinner}
          alt="Laddar"
          className="c-favoritebtn__spinner"
        />
      )}
    </div>
  );
};

export { FavoriteButton };

export default FavoriteButton;
