import React, { useEffect, useRef } from 'react';
import ShrinkedOpacityText from '../../components/ShrinkedOpacityText';
import ProductSingleDetails from './ProductSingleDetails';
import './ProductSingle.scss';
import RichText from '../../containers/RichText';
import { getElementScrollContainer } from '../../lib/scroll';
import {
  ProductsInOffer,
  RecommendedProducts,
  RelatedProducts
} from '../../components/ProductPanels';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';
import { InformationText } from '../../constants/InformationText';
import * as ga4 from '@citygross/analytics';
import { formatPrice } from './../../store/middleware/analytics';
import { capitalizeWord } from '../../lib/utils';
import { Link as CgLink } from '@citygross/components';
import { H2 } from '@citygross/typography';
import { IExtendedProduct } from '../../types/storefront/product';
import ProductNutritionTable from './ProductSingleDetails/ProductNutritionTable';
import { theme } from '@citygross/design-tokens';
import { ProductHazardContainer } from './ProductHazard/ProductHazardContainer';

type TProductSingle = {
  currentBreadCrumb: any;
  isFlyout: boolean;
  product: IExtendedProduct;
};

const ProductSingle: React.FC<TProductSingle> = ({
  currentBreadCrumb,
  isFlyout,
  product
}) => {
  const rootElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToTop();

    try {
      const { gtin, name, url, brand, productStoreDetails } = product;
      const price = productStoreDetails?.prices;
      const promotion = price?.hasPromotion ? price.promotions?.[0] : null;

      const basePrice = price?.ordinaryPrice?.price || 0;
      const promoValue = promotion?.value || 0;
      const promoQuantity = promotion?.minQuantity || 1;

      const ga4Item = {
        value: promotion ? promoValue : price?.currentPrice?.price || 0,
        currency: 'SEK',
        items: [
          {
            item_id: gtin,
            item_name: name,
            item_brand: brand || 'City Gross',
            item_category: url?.substring(0, url.lastIndexOf('/') + 1) || '',
            price: basePrice,
            quantity: promoQuantity,
            discount: promotion
              ? Number(formatPrice(promoQuantity * basePrice - promoValue))
              : 0
          }
        ]
      };

      ga4.viewItemDetails(ga4Item);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [product]);

  const scrollToTop = () => {
    const scrollContainer = getElementScrollContainer(rootElementRef.current);
    if (!scrollContainer) return;

    scrollContainer.scrollTo?.(0, 0) || (scrollContainer.scrollTop = 0);
  };

  const {
    countryOfManufacture,
    countryOfOrigin,
    description,
    foodAndBeverageExtension,
    // @ts-ignore @TODO part of catering
    guaranteedDurability,
    handling,
    // @ts-ignore @TODO part of catering
    ingredientStatement,
    markings,
    mappedMarkings,
    mappedSafetyDataSheetInformation,
    // @ts-ignore @TODO part of catering
    precautionaryStatement,
    sortCountryInfo,
    storageInformation,
    superCategory,
    uniformResourceIdentifier
  } = product;

  const hasTexts =
    description ||
    ingredientStatement ||
    markings?.length ||
    storageInformation ||
    guaranteedDurability ||
    precautionaryStatement ||
    handling?.minimumLifespanFromTimeOfArrivalInDays ||
    foodAndBeverageExtension?.ingredientStatement ||
    foodAndBeverageExtension?.nutrientInformations ||
    mappedSafetyDataSheetInformation;

  const isTobaccoProduct = superCategory === 'Tobak';

  const now = Date.now();
  const sortCountries = sortCountryInfo?.filter(
    ({ validFrom, validTo, mainCountry }) => {
      const isValidFrom = new Date(validFrom).getTime() < now;
      const isValidTo = new Date(validTo).getTime() > now;
      return isValidFrom && isValidTo && mainCountry !== 'X';
    }
  );

  const offerId = product.productStoreDetails?.prices?.promotions[0]?.name;

  const isFassLink = uniformResourceIdentifier?.includes('www.fass.se');

  const countryDescriptions = sortCountries
    ?.filter(c => c.countryDesc)
    .map(c => c.countryDesc)
    .join(', ');

  const nutrientStatement =
    foodAndBeverageExtension?.nutrientInformations?.[0]?.nutrientStatement;

  const showCountryOfManufacture = !/^03|^06|^07|^120211/.test(
    product.bfCategoryCode
  );

  return (
    <div className="c-productdetails" ref={rootElementRef}>
      {currentBreadCrumb && <PageTitle currentPage={currentBreadCrumb} />}

      <ProductSingleDetails product={product} />

      {hasTexts && (
        <div className="c-productdetails__texts">
          <RichText>
            <ShrinkedOpacityText>
              {description && (
                <div className="c-productdetails--label">
                  <H2 color={theme.palette?.darkest}>Beskrivning</H2>

                  <div>
                    <RichText text={description} />
                  </div>

                  {isFassLink && (
                    <p>
                      Läs mer på{' '}
                      <CgLink
                        hrefPath={uniformResourceIdentifier}
                        target="_blank"
                      >
                        fass
                      </CgLink>
                    </p>
                  )}
                </div>
              )}

              {storageInformation && (
                <div className="c-productdetails--label">
                  <H2 color={theme.palette?.darkest}>Förvaring</H2>

                  <div>
                    <RichText text={storageInformation} />
                  </div>
                </div>
              )}

              {handling?.minimumLifespanFromTimeOfArrivalInDays && (
                <div className="c-productdetails--label">
                  <H2 color={theme.palette?.darkest}>Garanterad hållbarhet</H2>

                  <p>
                    Minst {handling.minimumLifespanFromTimeOfArrivalInDays}
                    {handling.minimumLifespanFromTimeOfArrivalInDays === 1
                      ? ' dag '
                      : ' dagar '}
                    (vårt genomsnitt{' '}
                    {handling.averageLifespanFromTimeOfArrivalInDays} dagar)
                  </p>
                </div>
              )}

              {ingredientStatement && (
                <div className="c-productdetails--label">
                  <H2 color={theme.palette?.darkest}>Ingredienser</H2>

                  <div>
                    <RichText text={ingredientStatement} />
                  </div>
                </div>
              )}

              {mappedMarkings && mappedMarkings.length > 0 && (
                <div className="c-productdetails--label">
                  <H2 color={theme.palette?.darkest}>Märkningar</H2>

                  <p>
                    {mappedMarkings
                      .map(({ name, code }) => name || code)
                      .join(', ')}
                  </p>
                </div>
              )}

              {nutrientStatement && (
                <div className="c-productdetails--label">
                  <H2 color={theme.palette?.darkest}>
                    Näringsinnehåll per 100g
                  </H2>

                  <ProductNutritionTable
                    nutritionStatement={nutrientStatement}
                  />
                </div>
              )}

              {foodAndBeverageExtension?.ingredientStatement && (
                <div className="c-productdetails--label">
                  <H2 color={theme.palette?.darkest}>Ingredienser</H2>

                  <div>
                    <RichText
                      text={foodAndBeverageExtension.ingredientStatement}
                    />
                  </div>
                </div>
              )}

              {precautionaryStatement && (
                <div className="c-productdetails--label">
                  <H2 color={theme.palette?.darkest}>Varningar</H2>

                  <div>
                    <RichText text={precautionaryStatement} />
                  </div>
                </div>
              )}

              {mappedSafetyDataSheetInformation && (
                <ProductHazardContainer
                  mappedSafetyDataSheetInformation={
                    mappedSafetyDataSheetInformation
                  }
                />
              )}

              {countryOfOrigin && (
                <div className="c-productdetails--label">
                  <H2 color={theme.palette?.darkest}>Ursprungsland</H2>

                  <div>{capitalizeWord(countryOfOrigin)}</div>
                </div>
              )}

              {countryDescriptions && (
                <div className="c-productdetails--label">
                  <H2 color={theme.palette?.darkest}>
                    Möjliga länder vid ersättningar
                  </H2>

                  <p>{countryDescriptions}</p>

                  <p>
                    I kassan kan du tacka nej till ersättningsvaror om du inte
                    vill tillåta ersättning från annat land.
                  </p>
                </div>
              )}

              {countryOfManufacture && showCountryOfManufacture && (
                <div className="c-productdetails--label">
                  <H2 color={theme.palette?.darkest}>Förpackningsland</H2>

                  <div>{capitalizeWord(countryOfManufacture)}</div>
                </div>
              )}

              <div className="c-productdetails--label">
                <H2 color={theme.palette?.darkest}>Bra att veta</H2>

                <div>
                  <RichText text={InformationText} />
                </div>
              </div>
            </ShrinkedOpacityText>
          </RichText>
        </div>
      )}

      {offerId && (
        <ProductsInOffer
          isFlyout={isFlyout}
          offerId={offerId}
          productId={product.id}
        />
      )}

      {!isTobaccoProduct && (
        <RelatedProducts
          key={product.id}
          categoryCode={product.bfCategoryCode}
          bfabId={product.id}
          offerName={product.productStoreDetails?.prices?.promotions?.[0]?.name}
        />
      )}
      {!isTobaccoProduct && (
        <RecommendedProducts
          productId={product.id}
          excludeCategoryByCode={product.bfCategoryCode}
          isFlyout={isFlyout}
        />
      )}
    </div>
  );
};

export default ProductSingle;
